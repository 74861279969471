<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile'">
    <v-container fill-height :style="!desktop ? 'padding-top: 120px !important' : ''">
      <v-row justify="center">
        <v-card :width="desktop ? '75%' : '90%'" class="welcome-card" :class="{ 'animated shake': loginError }">
          <v-form ref="form">
            <grid
              :class-left="(desktop ? 'registration-form-border-right' : '') + ' pa-0'"
              class-right="px-8 pb-10 pt-0 py-md-10 px-md-12 py-lg-10 px-lg-12"
            >
              <template v-slot:left>
                <div class="welcome-container" :class="desktop ? 'tway-violet-dark' : ''">
                  <div
                    style="z-index: 10"
                    class="full-height tway-violet-dark white--text px-5 pt-10 pb-0 py-md-6 px-md-5 py-lg-10 px-lg-10"
                  >
                    <div class="mb-4">
                      <v-row class="ma-0" :justify="mobile ? 'center' : 'start'">
                        <span :class="mobile ? 'text-h6 text-center text-md-h6 text-lg-h5' : 'login-title'">
                          Te damos la bienvenida a
                          <span>Tway</span>
                        </span>
                      </v-row>
                    </div>
                    <div v-if="desktop" class="mb-4">
                      <span class="login-subtitle"
                        >La primera plataforma especializada en Transformación que te orienta paso a paso, en qué hacer,
                        cómo y con quién ajustar tu modelo de negocio a la Economía Digital.</span
                      >
                    </div>
                  </div>
                  <svg v-if="mobile" style="margin-top: -5vw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path
                      fill="#4A148C"
                      fill-opacity="1"
                      d="M0,256L60,240C120,224,240,192,360,176C480,160,600,160,720,176C840,192,960,224,1080,229.3C1200,235,1320,213,1380,202.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                    />
                  </svg>
                </div>
              </template>
              <template v-slot:right>
                <v-row justify="center" class="mb-4 ma-0">
                  <span class="text-h5 text-md-h4">Iniciar sesión</span>
                </v-row>
                <v-row justify="center" v-if="loginError" :class="{ 'animated shake': loginError }" class="mb-4 ma-0">
                  <span class="red--text text-subtitle-1">Ingresaste un usuario o contraseña inválido</span>
                </v-row>
                <div>
                  <v-text-field
                    label="Correo electrónico"
                    name="email"
                    prepend-icon="person"
                    type="text"
                    v-model="model.username"
                    :rules="[required('email'), validateEmail()]"
                    required
                  />
                </div>
                <div class="mb-4">
                  <v-text-field
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="lock"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    v-model="model.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[required('contraseña')]"
                    required
                  />
                </div>
                <div class="mb-4">
                  <grid
                    justify-row="center"
                    justify-self-right="end"
                    align-self-left="center"
                    :class-left="mobile ? 'pa-0 mb-3' : 'pl-0 py-0'"
                    :class-right="mobile ? 'pa-0' : 'pr-0 py-0'"
                  >
                    <template v-slot:alone>
                      <div class="px-4">
                        <v-btn
                          rounded
                          color="tway-violet"
                          class="button-text white--text py-5 elevation-6"
                          :loading="loginLoading"
                          @click="login"
                          block
                          id="enter-tway"
                        >
                          Ingresar a tway
                        </v-btn>
                      </div>
                    </template>
                  </grid>
                </div>
                <div v-if="desktop" class="no-account-container mb-8 mt-6">
                  <span class="roboto text-subtitle-2 text-uppercase pr-5">¿Aún no tienes una cuenta?</span>
                  <a href="/registration" style="text-decoration: none">
                    <v-btn class="button-text" color="tway-violet" rounded outlined id="register-mobile">
                      Regístrate
                    </v-btn>
                  </a>
                </div>
                <grid v-if="mobile" justify-row="center" class="ml-0 mt-0 mr-0 mb-4">
                  <template v-slot>
                    <router-link
                      :to="{ name: 'forgot-password' }"
                      class="tway-violet--text text-subtitle-1 roboto"
                      id="forgot-password-mobile"
                    >
                      Olvidé mi contraseña
                    </router-link>
                  </template>
                </grid>
                <div v-if="mobile" class="no-account-container-mobile mb-8 mt-6">
                  <span class="roboto text-subtitle-2 text-uppercase pb-5">¿Aún no tienes una cuenta?</span>
                  <a href="/registration" style="text-decoration: none">
                    <v-btn class="button-text" color="tway-violet" rounded outlined id="register-mobile">
                      Regístrate
                    </v-btn>
                  </a>
                </div>
                <grid v-if="desktop" justify-row="center" class="ml-0 mt-0 mr-0 mb-4">
                  <template v-slot>
                    <router-link
                      :to="{ name: 'forgot-password' }"
                      class="tway-violet--text text-subtitle-1 roboto"
                      id="forgot-password-desktop"
                    >
                      Olvidé mi contraseña
                    </router-link>
                  </template>
                </grid>
              </template>
            </grid>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<style lang="scss" scoped>
.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.welcome-card {
  border-radius: 10px;
  overflow: hidden;
  max-width: 900px;
}
.no-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-account-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
}
.login-title {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #ffffff;
}
.register-desk {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #7319d5;
}
.register-mobile {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Tway / Violeta */

  color: #7319d5;
}
</style>

<script>
import { mapActions } from "vuex";

import ValidatorsService from "@/services/validatorsService";
import RowTwo from "@/components/grid/RowTwo.vue";

export default {
  name: "Login",
  components: {
    grid: RowTwo,
  },
  data() {
    return {
      loginError: false,
      showPassword: false,
      loginLoading: false,
      model: {
        username: null,
        password: null,
      },
      remember: false,
    };
  },
  created() {
    localStorage.removeItem("accessToken");
  },
  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),
    validateEmail: () => ValidatorsService.validateEmail(),
    ...mapActions("authentication", ["doLogin", "doLogout"]),
    ...mapActions("ranking", ["removecache"]),
    login() {
      if (this.$refs.form.validate()) {
        if (this.model.username && this.model.password) {
          this.loginError = false;
          this.loginLoading = true;
          this.doLogin(this.model)
            .then((redirectTo) => {
              this.removecache();
              if (redirectTo.name == "complete-profile-new" || redirectTo.name == "complete-profile") {
                this.$router.push(redirectTo);
              } else {
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect);
                } else {
                  this.$router.push(redirectTo);
                }
              }
            })
            .catch((err) => {
              this.loginError = true;
              this.$log.error("Unexpected error: ", err);
              // Clean local Storage
              this.doLogout();
            })
            .finally(() => (this.loginLoading = false));
        }
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    mid() {
      return this.$vuetify.breakpoint.md;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
